@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face { 
  font-family: 'GRIFTERBold700';
  src: 
      url('./assets/font/GRIFTERBold700.otf')  format('opentype'); 
  font-weight: 700; 
  font-style: normal; 
  font-size: 20px;
  line-height: 20.6px;
}
/* blogh-trial.regular */
@font-face { 
  font-family: 'bloghtrial';
  src: 
      url('./assets/font/blogh-trial.regular.otf')  format('opentype'); 
  font-weight: 700; 
  font-style: normal; 
  font-size: 20px;
  line-height: 20.6px;
}

@font-face { 
  font-family: 'ttMusscelsTRL';
  src: 
      url('./assets/font/tt_mussels/TT Mussels Trial Regular.otf')  format('opentype'); 
}

.ttMusscelsTRL{
  font-family: 'ttMusscelsTRL';
}

@font-face { 
  font-family: 'DemiBoldttMusscelsTRL';
  src: 
      url('./assets/font/tt_mussels/TT Mussels Trial DemiBold.otf')  format('opentype'); 
}

.DemiBoldttMusscelsTRL{
  font-family: 'DemiBoldttMusscelsTRL';
}

.text_shadow{
  /* text-shadow: 0px 4px 4px 0px #00000040; */
  text-shadow: 2px 7px 4px rgba(128, 128, 138, 0.5);
}

.flip-vertical {
  --transform-scale-y: -1;
}

.bottom{
  bottom: 12%;
}

@media screen and (min-width: 360px) {
  .bottom{
    bottom: 7%;
  }
}

@media screen and (min-width: 390px) {
  .bottom{
    bottom: 7%;
  }
}

@media screen and (min-width: 423px) {
  .bottom{
    bottom: 10%;
  }
}

@media screen and (min-width: 541px) {
  .bottom{
    bottom: 14%;
  }
}

@media screen and (min-width: 591px) {
  .bottom{
    bottom: 15%;
  }
}

@media screen and (min-width: 650px) {
  .bottom{
    bottom: 17%;
  }
}

.bottom_large{
  bottom: 40%;
}



@media only screen and (max-width: 1200px) {
  .blend-image { 
    width: 27vw;
  }
}

.real-image { 
  width: 15vw;
  height: 35vh;
  background-repeat: no-repeat, repeat;
  background-image: url("./assets/icons/Asset\ 3\ 2.png");
  background-blend-mode: multiply;
  background-size: cover;
  opacity: 0.3;
}

.bg-our_story_md{
  background-repeat: no-repeat, repeat;
  background-image: url("./assets/icons/bg new 1.png");
  background-blend-mode: multiply;
  background-size: cover;
}

.bg-our_story{
  background-repeat: no-repeat, repeat;
  background-image: url("./assets/icons/bg-our-story-mobile.png");
  background-blend-mode: multiply;
  background-size: cover;
}

.bg-our_story_small {
  background-repeat: no-repeat;
  background-image: url("./assets/icons/bg-our-story-mobile.png");
  background-blend-mode: multiply;
  background-size: 100% 100%; /* Cover the entire element */
  background-color: rgba(188, 186, 186, 0.734); /* Adjust the alpha (0.5) for desired transparency */
}


.image-filter {
  filter: grayscale(100%) sepia(20%);
}

@media only screen and (min-width: 1600px) {
  .real-image { 
    width: 12vw;
    height: 35vh;
    background-repeat: no-repeat, repeat;
    background-image: url("./assets/icons/Asset\ 3\ 2.png");
    background-blend-mode: multiply;
    background-size: cover;
    opacity: 0.3;
  }
}

.text{
  font-size: calc(8vh);
}

.flip-horizontal {
  --transform-scale-x: -1;
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.parallax-container {
  position: relative;
  height: 400px; /* Adjust height as needed */
  overflow-x: hidden;
  overflow-y: auto;
}
parallax-container

.parallax-content {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: #fff;
  transition: transform 0.3s ease-out; /* Smooth transition for parallax effect */
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}


.grediant{
  background-clip: 'text' ;
  -webkit-background-clip: 'text';
  color: transparent;
  background-image: linear-gradient(to bottom, #F5B352, #FF5B5B);
  /* backgroundClip: 'text', WebkitBackgroundClip: 'text', color: 'transparent', backgroundImage: 'linear-gradient(to bottom, #F5B352, #FF5B5B)' */
}

.parent-div:hover p {
  background-color: rgb(236 72 153);
}


body {
  background-color: "#010519";
  font-family: 'GRIFTERBold700', sans-serif; 
  text-align: center; 
}
.font_blogh{
  font-family: 'bloghtrial';
}

.blend-image { 
  width: 27vw;
  height: 310px;
  background-repeat: no-repeat, repeat;
  background-image: url("./assets/icons/Layer 1.png");
  background-blend-mode: multiply;
  background-size: cover;
  opacity: 0.3;
}

.chair-image
{
  width: 20vw;
  height: 400px;
  margin-top: 2rem;
  background-repeat: no-repeat, repeat;
  background-image: url("./assets/icons/Asset\ 5\ 2.png");
  background-blend-mode: multiply;
  background-size: cover;
  opacity: 0.1;
}

.marquee h1{
  animation: slide 6000ms linear infinite;
}

@keyframes slide {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-100%);
  }
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color:orange !important;
}

.borderRadius{
  border-radius: 20px;
}

.white_background_noise {
  background-image: url("./assets/icons/white_background_noise.png"); 
}

.lets_talk_bg {
  background-image: url("./assets/icons/lets_talk_bg.png"); 
}

.black-noise{
  background-image: url("./assets/icons/black_noise.svg"); 
}

.automotive_left_div{
  position: absolute; 
  bottom: 0; 
  background: #C75092;
  width: 4%;
  height: 94%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 10rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.automotive_right_div{
  position: absolute; 
  bottom: 0; 
  right:0;
  background: #C75092;
  width: 4%;
  height: 94%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 10rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.automotive_top_div{
  position: absolute; 
  top:0;
  background: #C75092;
  width: 99%;
  height: 4%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 0.8rem;
  margin-left: 0.1rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.automotive_bottom_div{
  position: absolute; 
  bottom:0;
  background: #C75092;
  width: 99%;
  height: 17%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  z-index: 10;
  margin-bottom: 2.2%;
}

@media only screen and (min-width: 1600px) {
  .automotive_bottom_div{
    position: absolute; 
    bottom:0;
    background: #C75092;
    width: 99%;
    height: 17%;
    transition: .5s ease;
    opacity: 0;
    border-radius: 10px;
    z-index: 10;
    margin-bottom: 1.2%;
  }
}


.text_bottom_all{
  bottom: 2rem;
}

@media only screen and (min-width: 1600px) {
  
.text_bottom_all{
  bottom: 2.5rem;
}
}

.text_bottom_three{
  bottom: 1.3rem;
}

@media only screen and (min-width: 1600px) {
  
.text_bottom_three{
  bottom: 2.5rem;
}
}

.text_bottom_two{
  bottom: 2rem;
}

@media only screen and (min-width: 1600px) {
  
.text_bottom_two{
  bottom: 2rem;
}
}

.image_size{
  height: 3rem;
}
@media only screen and (min-width: 1600px) {
  
  .image_size{
    height: 4rem;
  }
  }

  .image_size_three{
    height: 3rem;
  }
  @media only screen and (min-width: 1600px) {
    
    .image_size_three{
      height: 5rem;
    }
    }


.texthover{
  z-index: 100;
}

.whitelogohover
{
  opacity: 0;
  z-index: 100;
}

.automotiveDiv:hover .whitelogohover {
  opacity: 1;
}

.automotiveDiv:hover .texthover {
  opacity: 1;
}

.automotiveDiv:hover .automotive_bottom_div {
  opacity: 1;
}

.automotiveDiv:hover .automotive_top_div {
  opacity: 1;
}

.automotiveDiv:hover .automotive_right_div {
  opacity: 1;
}

.automotiveDiv:hover .automotive_left_div {
  opacity: 1;
}

.narrative_left_div{
  position: absolute; 
  bottom: 0; 
  background: #4A588E;
  width: 2%;
  height: 89%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 12rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.narrative_right_div{
  position: absolute; 
  bottom: 0; 
  right:0;
  background: #4A588E;
  width: 2%;
  height: 89%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 10rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.narrative_top_div{
  position: absolute; 
  top:0;
  background: #4A588E;
  width: 99%;
  height: 5%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 0.8rem;
  margin-left: 0.1rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.narrative_bottom_div{
  position: absolute; 
  bottom:0;
  background: #4A588E;
  width: 99%;
  height: 34%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  z-index: 10;
  margin-bottom: 2.2%;
}

@media only screen and (min-width: 1600px) {
  .narrative_bottom_div{
    position: absolute; 
    bottom:0;
    background: #4A588E;
    width: 99%;
    height: 33%;
    transition: .5s ease;
    opacity: 0;
    border-radius: 10px;
    z-index: 10;
    margin-bottom: 1.2%;
  }
}




.narrativeDiv:hover .narrative_bottom_div {
  opacity: 1;
}

.narrativeDiv:hover .narrative_top_div {
  opacity: 1;
}

.narrativeDiv:hover .narrative_right_div {
  opacity: 1;
}

.narrativeDiv:hover .narrative_left_div {
  opacity: 1;
}

.narrativeDiv:hover .whitelogohover {
  opacity: 1;
}

/* CGI - VFX */

.cgi_left_div{
  position: absolute; 
  bottom: 0; 
  background: #F5B352;
  width: 2%;
  height: 89%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 12rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.cgi_right_div{
  position: absolute; 
  bottom: 0; 
  right:0;
  background: #F5B352;
  width: 2%;
  height: 89%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 10rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.cgi_top_div{
  position: absolute; 
  top:0;
  background: #F5B352;
  width: 99%;
  height: 5%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 0.8rem;
  margin-left: 0.1rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.cgi_bottom_div{
  position: absolute; 
  bottom:0;
  background: #F5B352;
  width: 99%;
  height: 33%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  z-index: 10;
  margin-bottom: 2.2%;
}

@media only screen and (min-width: 1600px) {
  .cgi_bottom_div{
    position: absolute; 
    bottom:0;
    background: #F5B352;
    width: 99%;
    height: 30%;
    transition: .5s ease;
    opacity: 0;
    border-radius: 10px;
    z-index: 10;
    margin-bottom: 1.2%;
  }
}

.cgiDiv:hover .cgi_bottom_div {
  opacity: 1;
}

.cgiDiv:hover .cgi_top_div {
  opacity: 1;
}

.cgiDiv:hover .cgi_right_div {
  opacity: 1;
}

.cgiDiv:hover .cgi_left_div {
  opacity: 1;
}

.cgiDiv:hover .whitelogohover {
  opacity: 1;
}

/* Fashion - VFX */

.fashion_left_div{
  position: absolute; 
  bottom: 0; 
  background: #4A588E;
  width: 4%;
  height: 99%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 12rem;
  z-index: 10;
  margin-bottom: 0%;
  margin-left: 0%;
}

.fashion_right_div{
  position: absolute; 
  bottom: 0; 
  right:0;
  background: #4A588E;
  width: 4%;
  height: 99%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 10rem;
  z-index: 10;
  margin-bottom: 0%;
}

.fashion_top_div{
  position: absolute; 
  top:0;
  background: #4A588E;
  width: 99%;
  height: 5%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-left: 0.1rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.fashion_bottom_div{
  position: absolute; 
  bottom:0;
  background: #4A588E;
  width: 99%;
  height: 40%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  z-index: 10;
}

.fashionDiv:hover .fashion_bottom_div {
  opacity: 1;
}

.fashionDiv:hover .fashion_top_div {
  opacity: 1;
}

.fashionDiv:hover .fashion_right_div {
  opacity: 1;
}

.fashionDiv:hover .fashion_left_div {
  opacity: 1;
}

.fashionDiv:hover .whitelogohover {
  opacity: 1;
}

/* Food */

.food_left_div{
  position: absolute; 
  bottom: 0; 
  background: #F5B352;
  width: 4%;
  height: 99%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 12rem;
  z-index: 10;
  margin-bottom: 0%;
  margin-left: 0%;
}

.food_right_div{
  position: absolute; 
  bottom: 0; 
  right:0;
  background: #F5B352;
  width: 4%;
  height: 99%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 10rem;
  z-index: 10;
  margin-bottom: 0%;
}

.food_top_div{
  position: absolute; 
  top:0;
  background: #F5B352;
  width: 99%;
  height: 5%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-left: 0.1rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.food_bottom_div{
  position: absolute; 
  bottom:0;
  background: #F5B352;
  width: 99%;
  height: 40%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  z-index: 10;
}

.foodDiv:hover .food_bottom_div {
  opacity: 1;
}

.foodDiv:hover .food_top_div {
  opacity: 1;
}

.foodDiv:hover .food_right_div {
  opacity: 1;
}

.foodDiv:hover .food_left_div {
  opacity: 1;
}

.foodDiv:hover .whitelogohover {
  opacity: 1;
}

/* sport */

.sport_left_div{
  position: absolute; 
  bottom: 0; 
  background: #C75092;
  width: 4%;
  height: 99%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 12rem;
  z-index: 10;
  margin-bottom: 0%;
  margin-left: 0%;
}

.sport_right_div{
  position: absolute; 
  bottom: 0; 
  right:0;
  background: #C75092;
  width: 4%;
  height: 99%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-top: 10rem;
  z-index: 10;
  margin-bottom: 0%;
}

.sport_top_div{
  position: absolute; 
  top:0;
  background: #C75092;
  width: 99%;
  height: 5%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  margin-left: 0.1rem;
  z-index: 10;
  margin-bottom: 2.2%;
}

.sport_bottom_div{
  position: absolute; 
  bottom:0;
  background: #C75092;
  width: 99%;
  height: 40%;
  transition: .5s ease;
  opacity: 0;
  border-radius: 10px;
  z-index: 10;
}

.sportDiv:hover .sport_bottom_div {
  opacity: 1;
}

.sportDiv:hover .sport_top_div {
  opacity: 1;
}

.sportDiv:hover .sport_right_div {
  opacity: 1;
}

.sportDiv:hover .sport_left_div {
  opacity: 1;
}

.sportDiv:hover .whitelogohover {
  opacity: 1;
}